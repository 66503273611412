const defaultPermissions = {
    canView: false,
    canChange: false,
    canAdd: false,
    canDelete: false,
};

const getElementPermissions = (page, user) => {
    let canView = false;
    let canChange = false;
    let canAdd = false;
    let canDelete = false;
    
    if(user && user != {}) {
        const permissions = user.permissions || [];

        canView = permissions.filter(p => p === 'view_' + page).length > 0 || user.is_superuser;
        canChange = permissions.filter(p => p === 'change_' + page).length > 0 || user.is_superuser;
        canAdd = permissions.filter(p => p === 'add_' + page).length > 0 || user.is_superuser;
        canDelete = permissions.filter(p => p === 'delete_' + page).length > 0 || user.is_superuser;
    }

    return {
        canView: canView,
        canChange: canChange,
        canAdd: canAdd,
        canDelete: canDelete,
    }
}

export {
    defaultPermissions,
    getElementPermissions
}