const Footer = () => {
	return (
		<div className="container-fluid">
			<footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
				<div className="col-md-4 d-flex align-items-center">
					<a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
					</a>
					<span className="text-muted">Galamtec, ERP system</span>
				</div>
			</footer>
		</div>
	)
}

export default Footer;