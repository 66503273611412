// utils/navigation.js
import { useNavigate } from 'react-router-dom';

const useRedirectIfNoPermission = (permission) => {
  const navigate = useNavigate();

  if (!permission) {
    if (window.history.length > 0) {
      navigate(-1);
    } else {
      navigate('/');
    }
  }
};

export {
  useRedirectIfNoPermission,
};