import Container from 'react-bootstrap/esm/Container';
import '../../static/scss/Auth.scss';
import logo from '../../static/logo0.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import {useNavigate} from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../store/hooks';



const Auth = observer(() => {
    const authStore = useStore('authStore');
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [inProcess, setInProcess] = useState(false);

    const authorize = async (e) => {
        e.preventDefault();

        setErrors([]);
        setInProcess(true);
        await authStore.login(username, password).then((response) => {
            if(response.status === 200) {
                navigate('/');
            }
            const responseData = response?.response?.data;
            if(responseData && (responseData.non_field_errors || responseData.error)) {
                const serverErrors = responseData.non_field_errors || [responseData.error];
                setErrors(prevState => [...prevState, ...serverErrors]);
            }
        }).finally(() => {
            setInProcess(false);
        });
    }

    return (
        <>
            <Container className='auth-container'>
                <main className="form-signin w-100 m-auto">
                    <form className="text-center">
                        <img className="mb-4" src={logo} alt="" />
                        <h1 className="h3 mb-3 fw-normal">Войти в систему</h1>

                        <div className="form-floating">
                            <input type="text" className="form-control" id="floatingInput" placeholder="Логин" onChange={e => setUsername(e.target.value)} value={username}/>
                            <label htmlFor="floatingInput">Логин</label>
                        </div>
                        <div className="form-floating">
                            <input type="password" className="form-control" id="floatingPassword" placeholder="Пароль" onChange={e => setPassword(e.target.value)} value={password}/>
                            <label htmlFor="floatingPassword">Пароль</label>
                        </div>
                        { errors.length > 0 &&
                            errors.map(e => {
                                return <div className={"alert alert-danger"} role="alert">
                                    {e}
                                </div>
                            })
                        }
                        <button onClick={authorize} className="w-100 btn btn-lg btn-primary" type="submit" disabled={inProcess}>Войти</button>
                        <p className="mt-5 mb-3 text-muted">© {new Date().getFullYear()}</p>
                    </form>
                </main>
            </Container>
        </>
    )
});

export default Auth;