import {useEffect, useState} from "react";
import { Modal } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import $api from "../../http/api.config";
import useAlert from "../../util/Alert";
import { useStore } from "../../store/hooks";
import { observer } from "mobx-react-lite";
import {ru} from "date-fns/locale";
import DatePicker from "react-datepicker";
import '../../static/scss/modals/overwork-modal.scss';

const AddOverworkModal = ({show, onHide}) => {
	const authStore = useStore("authStore");
	const [showLoader, setShowLoader] = useState(false);
	const [alert, setAlert] = useAlert();
	const [errors, setErrors] = useState({});
	const [customers, setCustomers] = useState([]);
	const today = new Date();
	const initialOverwork = {
		name: "",
		wasted_time: 1,
		start_date: today,
		end_date: today,
		user: authStore.getUser?.id,
		organization: null
	};
	const [overwork, setOverwork] = useState(Object.assign({}, initialOverwork));

	useEffect(() => {
		// получаем список заказчиков
		$api.get('/api/v1/customers/').then((response) => {
            setCustomers([{id: "", name: "Нет"}].concat(response.data));
        });
	}, []);

	const addOverwork = (e) => {
		e.preventDefault();
		overwork.user = authStore.getUser?.id;
		const errors = validateOverwork();
		if(Object.keys(errors).length === 0) {
			setShowLoader(true);
			$api.post('/api/v1/overworks/', overwork).then(response => {
			    setAlert(response);

				if(response.status >= 200 && response.status < 300) {
					setOverwork(Object.assign({}, initialOverwork));
				}
			}).catch(err => {
			    setAlert(err.response);
			}).finally(() => {
			    setShowLoader(false);
			});
		}
	}

	const validateOverwork = () => {
		const errors = {};
		// Проверка сообщения
		if (!overwork.name || overwork.name.trim() === "") {
			errors.name = "Сообщение не может быть пустым.";
		}

		// Проверка даты (не должна быть в будущем)
		if (new Date(overwork.date) > new Date()) {
			errors.date = "Дата не может быть в будущем.";
		}

		// Проверка пользователя
		if (!overwork.user || typeof overwork.user !== "number") {
			errors.user = "ID пользователя должен быть указан.";
		}
		setErrors(errors);
		return errors;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
        setOverwork((prev) => ({
            ...prev,
            [name]: value,
        }));
	}

	return (
		<Modal  show={show}
				onHide={onHide}
				scrollable={true}>
			<Modal.Header closeButton>
				<Modal.Title>Переработка</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form method='POST' id="addOverworkForm">
					<div className="row mb-2 text-start">
						<div className="col col-12">
							<label htmlFor="name">Проделанная работа</label>
						</div>
						<div className="col col-12">
							<textarea
								className='form-control'
								id="name"
								rows='7'
								name="name"
								onChange={handleChange}
								value={overwork.name || ""}/>
							{errors.name &&
								<span className="d-block mt-1 text-danger">{errors.name}</span>}
						</div>
					</div>
					<div className="row mb-2 text-start">
						<div className="col col-12">
							<label htmlFor="wasted_time">Потраченное время, ч</label>
						</div>
						<div className="col col-12">
							<input
								type="number"
								className="form-control"
								id="wasted_time"
								name='wasted_time'
								min='0'
								onChange={handleChange}
								value={overwork.wasted_time}/>
							{errors.wasted_time && <span
								className="d-block mt-1 text-danger">{errors.wasted_time}</span>}
						</div>
					</div>
					<div className="row mb-2 text-start">
						<div className="col col-12">
							<label htmlFor="date">Дата начала</label>
						</div>
						<div className="col col-12">
							<DatePicker
								className="form-control"
								selected={new Date(overwork.start_date)}
								onChange={date => {
									setOverwork({
										...overwork,
										start_date: new Date(date).toISOString(),
									});
								}}
								locale={ru}
								calendarStartDay={1}
								popperPlacement="bottom-end"
								name="date"
								dateFormat="Pp"
								timeCaption="Время"
								showTimeSelect
							/>
							{errors.date &&
								<span className="d-block mt-1 text-danger">{errors.date}</span>}
						</div>
					</div>
					<div className="row mb-2 text-start">
						<div className="col col-12">
							<label htmlFor="date">Дата завершения</label>
						</div>
						<div className="col col-12">
							<DatePicker
								className="form-control"
								selected={new Date(overwork.end_date)}
								onChange={date => {
									setOverwork({
										...overwork,
										end_date: new Date(date).toISOString(),
									});
								}}
								dateFormat="Pp"
								locale={ru}
								calendarStartDay={1}
								popperPlacement="bottom-end"
								name="date"
								timeCaption="Время"
								showTimeSelect
							/>
							{errors.date &&
								<span className="d-block mt-1 text-danger">{errors.date}</span>}
						</div>
					</div>
					<div className="row mb-2 text-start">
						<div className="col col-12">
							<label htmlFor="customer">Организация</label>
						</div>
						<div className="col col-12">
							<select className="form-control"
									name='organization'
									onChange={handleChange} >
								{customers && customers.map((item, key) => {
									return <option key={key} value={item.id || ""}>{item.name}</option>
								})}
							</select>
						</div>
					</div>
				</form>
				{alert && <div className="row">
					<div className="col">
						{alert}
					</div>
				</div>}
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-between">
				{showLoader ?
					<div className="filter_loader_box">
						<div id="loader-4">
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
					:
					<div></div>
				}
				<div className="buttons">
					<Button variant="secondary" onClick={e => onHide()}>
						Отмена
					</Button>
					<Button variant="primary" className='ms-2' onClick={addOverwork}>
						Добавить
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	)
}

export default observer(AddOverworkModal);