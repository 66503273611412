import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Loader from "../loader/Loader";
import $api from '../../http/api.config';
import AddCustomerModal from "../modals/AddCustomerModal";
import { Context } from "../contexts/HomeContext";
import { Save, XLg, CheckLg } from "react-bootstrap-icons";
import { useStore } from "../../store/hooks";
import AreYouSureDeleteModal from "../modals/AreYouSureDeleteModal";
import useAlert from './../../util/Alert';
import { observer } from "mobx-react-lite";
import { useRedirectIfNoPermission } from "../../util/permissions/redirect";

const Customers = ({}) => {
    const authStore = useStore('authStore');
    const [alert, setAlert] = useAlert();
    const [customers, setCustomers] = useState([]);
    const [updatedCustomers, setUpdatedCustomers] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [isLoaded, setIsLoaded] =  useState(false);
    const [isProcessRunning, setIsProcessRunning] = useState(false);
    const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
    const [elementIdToDelete, setElementIdToDelete] = useState(null);
    const { permissions } = authStore;

    useRedirectIfNoPermission(permissions.customer.canView);

    useEffect(() => {
        const url = '/api/v1/customers/';
        $api.get(url).then(response => {
            setCustomers(response.data);
        }).catch(err => {
            setCustomers(null);
        }).finally(() => {
            setIsLoaded(true);
        });
    }, []);

    const handleShowAddModal = () => setShowAddModal(true);
    const handleCloseAddModal = () => setShowAddModal(false);

    const setCustomersFun = (customers) => {
        setCustomers(customers);
    }

    const updateCustomer = (id) => {
        const element = updatedCustomers.find(e => e.id === id);

        if(!element || !element.name.trim()) return;
        setIsProcessRunning(true);
        $api.put(`/api/v1/customers/${id}/`, element)
			.then(response => {
				let updatedElement = response.data;
                updatedElement.modified = true;

                setCustomers(prev => prev.map(item => item.id === updatedElement.id ? updatedElement : item));
                setUpdatedCustomers(prev => prev.filter(item => item.id !== updatedElement.id));
			}).finally(() => {
				setIsProcessRunning(false);
			});
    }

    const deleteCustomer = (id) => {
        $api.delete(`/api/v1/customers/${id}/`)
			.then(response => {
				setAlert(response);
                setCustomers(prev => prev.filter(item => item.id != id));
                setUpdatedCustomers(prev => prev.filter(item => item.id != id));
			})
			.catch(err => {
				setAlert(err.response);
			});
    }

    const handleCustomersChanged = (e, id) => {
        const newValue = e.currentTarget.textContent; // Получаем новое значение из input
        setUpdatedCustomers((prevUpdatedCustomers) => {
            const customer = customers.find((c) => c.id === id); // Находим объект по id в customers

            if (!customer) {
                return prevUpdatedCustomers;
            }

            if (newValue !== customer.name) {
                // Если значение изменилось
                const existingCustomer = prevUpdatedCustomers.find((c) => c.id === id);

                if (existingCustomer) {
                    // Если объект уже есть в updatedCustomers, обновляем его
                    return prevUpdatedCustomers.map((c) =>
                        c.id === id ? { ...c, name: newValue } : c
                    );
                } else {
                    // Если объекта нет, добавляем новый
                    return [...prevUpdatedCustomers, { ...customer, name: newValue }];
                }
            } else {
                // Если значение совпадает, удаляем из updatedCustomers
                return prevUpdatedCustomers.filter((c) => c.id !== id);
            }
        });
        setCustomers(prev => prev.map(item => {
            if(item.id === id) {
                item.modified = false;
            }
            return item;
        }));
    }

    const handleShowAreYouSureModal = (id) => {
        setElementIdToDelete(id);
        setShowAreYouSureModal(true);
    }
    const handleCloseAreYouSureModal = () => {
        setShowAreYouSureModal(false);
    }

    return (
        <Context.Provider value={
            {setCustomersFun}
        }>
            { isLoaded ?
                <div className="container py-4">
                    <div className="group_block text-dark">
                        <div className="title_block d-flex align-items-center mb-3">
                            <h3 className="title">
                                Заказчики
                            </h3>
                            { permissions.customer.canAdd &&
                                <Button variant="outline-secondary" className='ms-2' onClick={handleShowAddModal}>
                                    Добавить
                                </Button> }
                        </div>
                            <>
                                <div className="customers-table">
                                    {customers.map((element, index) => {
                                        const id = element.id;
                                        const isUpdated = updatedCustomers.find(e => e.id === id);
                                        return <div key={index} to={id + '/'} className="row m-0">
                                                    <div className="col col-1" data-label="№">{++index}</div>
                                                    <div 
                                                        className="col flex-grow-1" 
                                                        data-label="Наименование:" 
                                                        contentEditable={permissions.customer.canAdd}
                                                        suppressContentEditableWarning={true}
                                                        onInput={e => handleCustomersChanged(e, id)}
                                                    >
                                                            {element.name}
                                                    </div>
                                                    {permissions.customer.canChange && <div className="col-auto d-flex align-items-center">
                                                        <div className="manipulate-btn p-0 m-0">
                                                            { isUpdated
                                                                && 
                                                                <Save 
                                                                    onClick={() => updateCustomer(id)}
                                                                    className={"ms-2" + (isProcessRunning && "pe-none text-muted")}
                                                                    title="Сохранить" /> 
                                                            }
                                                            { (element.modified && !isUpdated && permissions.customer.canChange)
                                                                &&
                                                                <CheckLg className="ms-2 pe-none" color="green" />
                                                            }
                                                            { permissions.customer.canDelete &&
                                                                <XLg
                                                                    onClick={() => handleShowAreYouSureModal(id)}
                                                                    className="ms-2 d-block " 
                                                                    title="Удалить" />
                                                            }
                                                        </div>
                                                    </div>}
                                                </div>
                                    })}
                                </div>
                            </>
                    </div>
                </div>
            : <Loader/> }

            { isLoaded && 
                <AddCustomerModal show={showAddModal}
                                  onHide={handleCloseAddModal}
                                  setCustomers={setCustomers}
                />
            }

            <AreYouSureDeleteModal
				show={showAreYouSureModal}
				onHide={handleCloseAreYouSureModal}
				onSureFunc={deleteCustomer}
                elementId={elementIdToDelete}
                alert={alert}
			/>
        </Context.Provider>
    );
}

export default observer(Customers);