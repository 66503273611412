import { useState, useEffect } from "react";
import { Button } from 'react-bootstrap';
import $api from "../../http/api.config";
import 'bootstrap/dist/css/bootstrap.css';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { observer } from 'mobx-react-lite';
import {paymentOptions, statusOptions} from "../../util/choices/ProjectOptions";
import { useStore } from "../../store/hooks";
import { initializeFilterData } from '../../util/Filter';
import { toJS } from "mobx";

const FilterModal = observer(({
	show, 
	onHide, 
	setElements,
	setNextPageUrl
}) => {
	const baseStore = useStore("baseStore");
	const authStore = useStore("authStore");
	const [filterData, setFilterData] = useState(initializeFilterData());

	useEffect(() => {
		baseStore.setProjectsFilterSet(filterData);
		filter();
	}, [filterData]);

	const filter = () => {
		$api.get('/api/v1/projects/', {
			params: toJS(baseStore.projectsFilterSet)
		}).then((response) => {
            setElements([...response.data.results]);
			setNextPageUrl(response.data.next);
        });
	}

	const handleChange = (e) => {
		let { name, type, checked, value } = e.target;
		let newValue = type === 'checkbox' ? checked : value;

		if(type === 'checkbox') {
			newValue = checked;
		} else if(type === 'select-multiple') {
			newValue = Array.from(e.target.selectedOptions).filter(el => el.value.trim() !== '').map(el => el.value).join(',');
		}

		if(name === 'only_mine') {
			name = 'user';
			newValue = checked ? value : null;
		}
		if(name === 'all') {
			name = 'page_size';
			newValue = checked ? value : null;
		}
		
        if(newValue) {
			localStorage.setItem('filter_' + name, newValue);
			setFilterData({
				...filterData,
				[name]: newValue
			});
		} else {
			localStorage.removeItem('filter_' + name);
			delete filterData[name];
			setFilterData({...filterData});
		}
	}

	const dropFilterData = () => {
		Object.keys(localStorage).map(key => {
			key.startsWith('filter_') && localStorage.removeItem(key);
		});
		setFilterData({});
	}
	
	return (
		<Offcanvas show={show} onHide={onHide} placement="start">
			<Offcanvas.Header closeButton>
			<Offcanvas.Title>Фильтр</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
			<div className="row">
					<div className="col mb-2">
						<div className="form-switch">
							<input 	onChange={handleChange}
									className="form-check-input me-2" 
									type="checkbox" 
									name="all"
									defaultChecked={localStorage.getItem('filter_page_size') || false}
									value="5000" />
							<label className="form-label" htmlFor="all_projects">Все проекты</label>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col mb-2">
						<div className="form-switch">
							<input 	onChange={handleChange}
								    className="form-check-input me-2"
									type="checkbox"
									name="only_mine"
									defaultChecked={filterData.user === authStore.getUser?.id}
									value={authStore.getUser?.id}
							/>
							<label className="form-label" htmlFor="user">Только мои</label>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col mb-2">
						<label className="form-label" htmlFor="number">Статус</label>
						<select onChange={handleChange}
								className="form-select" 
								name="status"
								defaultValue={filterData['status']?.split(',')}
								size={7}
								multiple>
							<option value=''>Любой</option>
							{ statusOptions && statusOptions.map((item, key) => {
								return <option key={key} value={item.value}>{item.name}</option>
							}) }
						</select>
					</div>
				</div>
				<div className="row">
					<div className="col mb-2">
						<label className="form-label" htmlFor="number">Оплата</label>
						<select onChange={handleChange}
								className="form-control"
								name="payment"
								value={filterData['payment'] || ''}>
							<option value=''>Любая</option>
							{ paymentOptions && paymentOptions.map((item, key) => {
								return <option key={key} value={item.value}>{item.name}</option>
							}) }
						</select>
					</div>
				</div>
				<div className="row">
					<div className="col mb-3">
						<label className="form-label" htmlFor="number">Заказчик</label>
						<select onChange={handleChange}
								className="form-select" 
								name="customer"
								value={filterData['customer'] || ''}>
							<option value=''>Любой</option>
							{ baseStore.getCustomers && baseStore.getCustomers.map((item, key) => {
								return <option key={key} value={item.id}>{item.name}</option>
							}) }
						</select>
					</div>
				</div>
				<div className="row">
					<div className="col mb-3">
						<label className="form-label" htmlFor="number">Исполнитель</label>
						<select onChange={handleChange}
								className="form-select" 
								name="user"
								value={filterData['user'] || ''}>
							<option value=''>Любой</option>
							{ baseStore.getUsers && baseStore.getUsers.map((item, key) => {
								return <option key={key} value={item.id}>{item.last_name} {item.first_name}</option>
							}) }
						</select>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<Button onClick={dropFilterData} variant="outline-secondary">
							Сбросить
						</Button>
					</div>
				</div>
			</Offcanvas.Body>
		</Offcanvas>
	)
});

export default FilterModal;