import { periods } from "./variables";
import moment from 'moment';

const getDates = () => {
    const today = moment(); // текущая дата
    const startOfMonth = today.clone().startOf('month');
    const endOfMonth = today.clone().endOf('month');

    // Сколько дней в диапазоне до и после текущей даты
    const range = 15;

    const startDate = today.clone().subtract(range, 'days').startOf('day');
    const endDate = today.clone().add(range, 'days');

    const adjustedStartDate = startDate.diff(startOfMonth, 'days') <= 5 ? startOfMonth.toDate() : startDate.toDate();
    const adjustedEndDate = endOfMonth.diff(endDate, 'days') <= 5 ? endOfMonth.toDate() : endDate.toDate();
    adjustedStartDate.setHours(3);
    adjustedEndDate.setHours(3);
    return { adjustedStartDate, adjustedEndDate };
};

const getPeriod = (period, setStartDate, setEndDate) => {
    const today = moment(); // текущая дата

    let startDate, endDate;

    switch (period) {
        case 'year':
          startDate = today.clone().startOf('year'); // начало года
          endDate = today.clone().endOf('year'); // конец года
          break;
        case 'halfYear':
          if (today.month() < 6) {
            startDate = today.clone().startOf('year'); // начало первого полугодия
            endDate = today.clone().month(5).endOf('month'); // конец первого полугодия
          } else {
            startDate = today.clone().month(6).startOf('month'); // начало второго полугодия
            endDate = today.clone().endOf('year'); // конец второго полугодия
          }
          break;
        case 'quarter':
          startDate = today.clone().startOf('quarter'); // начало квартала
          endDate = today.clone().endOf('quarter'); // конец квартала
          break;
        case 'month':
          startDate = today.clone().startOf('month'); // начало месяца
          endDate = today.clone().endOf('month'); // конец месяца
          break;
        case 'day':
          startDate = today.clone().startOf('day'); // начало дня
          endDate = today.clone().endOf('day'); // конец дня
          break;
        default:
          break;
    }

    // Устанавливаем даты в состояние
    setStartDate(startDate.toDate());
    setEndDate(endDate.toDate());
};

export { getDates, getPeriod };