const getFullUserNameOrUsername = (users, user_id) => {
    const user = users.find(e => e.id === user_id);
    if (!user) return '-';
    return user.first_name.trim() 
        ? `${user.first_name} ${user.last_name || ''}`.trim() 
        : user.username;
};

export {
    getFullUserNameOrUsername,
}