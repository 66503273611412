import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Loader from '../../loader/Loader';
import $api from '../../../http/api.config';
import { useStore } from '../../../store/hooks';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import { registerLocale } from "react-datepicker";
import useAlert from './../../../util/Alert';
import SubtasksBlock from '../../parts/SubtasksBlock';
import { observer } from 'mobx-react-lite';
import { paymentOptions, approvalStatusOptions } from "../../../util/choices/ProjectOptions";
import AreYouSureDeleteModal from "../../modals/AreYouSureDeleteModal";
import { getFullUserNameOrUsername } from '../../../util/user';
import { toJS } from 'mobx';
import { 
	PencilSquare,
	Trash 
} from "react-bootstrap-icons";

const ProjectDetail = () => {
	const authStore = useStore("authStore");
	const baseStore = useStore("baseStore");
	registerLocale("ru", ru);

	const navigate = useNavigate();
	const params = useParams();
	const [project, setProject] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [alert, setAlert] = useAlert();
	const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
	const [isProcessRunning, setIsProcessRunning] = useState(false);
	const [createdBy, setCreatedBy] = useState('Не указан');
	const { permissions } = authStore;

	const elementId = params.id;

	useEffect(() => {
		$api.get("/api/v1/projects/" + elementId + "/").then(response => {
			setProject(response.data);
			setIsLoaded(true);
			response.data.created_by && setCreatedBy(getFullUserNameOrUsername(toJS(baseStore.getUsers), response.data.created_by));
		});
	}, []);

	const updateElement = () => {
		setIsProcessRunning(true);
		$api.put(`/api/v1/projects/${project.id}/`, project)
			.then(response => {
				setAlert(response);
			})
			.catch(err => {
				setAlert(err.response);
			}).finally(() => {
				setIsProcessRunning(false);
			});
	}

	const deleteElement = (id) => {
		$api.delete(`/api/v1/projects/${id}/`)
			.then(response => {
				navigate("/");
			})
			.catch(err => {
				setAlert(err.response);
			});
	}

	const propChanged = (e) => {
		const { name, type, value, checked } = e.target;

		setProject((prevProject) => ({
			...prevProject,
			[name]: type === 'checkbox' ? checked : value,
		}));
	}

	const dateChanged = (name, date) => {
		const adjustedDate = new Date(date);
		const year = adjustedDate.getFullYear();
		const month = String(adjustedDate.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
		const day = String(adjustedDate.getDate()).padStart(2, '0');
		const formattedDate = `${year}-${month}-${day}`; // Формат YYYY-MM-DD

		setProject({
			...project,
			[name]: formattedDate
		});
	}

	const handleEditButton = () => setDisabled(!disabled);

	const handleShowAreYouSureModal = (id) => {
        setShowAreYouSureModal(true);
    }
    const handleCloseAreYouSureModal = () => {
        setShowAreYouSureModal(false);
    }

	return (
		<>
			{isLoaded ?
				<div className="detail-page">
					<div className="container-fluid text-start">
						<div className="bg-white rounded">
							<div className="row">
								<div className="col text-center">
									<h2>Проект</h2>
								</div>
							</div>
							{(permissions.project.canChange || permissions.project.canDelete) && <div className="row">
								<div className="col d-flex justify-content-end">
									{ permissions.project.canChange && 
										<div onClick={handleEditButton} className="manipulate-btn">
											<PencilSquare />
										</div>}
									{ permissions.project.canDelete && 
										<div onClick={handleShowAreYouSureModal} className="manipulate-btn ms-3">
											<Trash />
										</div> }
								</div>
							</div>}
							<div className="content">
								<form method='POST' id='project-form'>
									<div className="row">
										<div className="col">
											<p className='label'>Номер заказа:</p>
										</div>
										<div className="col">
											<span className='font-weight-bold'>
												<input
													onChange={propChanged}
													name="project_number"
													className="form-control"
													type="text"
													value={project && project.project_number}
													disabled={disabled}/>
											</span>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<span className='label'>Номер заказа у заказчика:</span>
										</div>
										<div className="col">
											<span>
												<input
													onChange={propChanged}
													name="customer_project_number"
													className="form-control" type="text"
													defaultValue={project && project.customer_project_number}
													disabled={disabled}/>
											</span>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<span className='label'>Наименование изделия:</span>
										</div>
										<div className="col">
											<span>
												<input onChange={propChanged}
													   name="name"
													   className="form-control"
													   type="text"
													   value={project && project.name}
													   disabled={disabled}/>
											</span>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<span className='label'>Исполнитель:</span>
										</div>
										<div className="col">
											<select onChange={propChanged}
													name="user"
													className="form-select"
													value={project && project.user}
													disabled={disabled}>
												<option value="null">Не назначен</option>
												{baseStore.getUsers && baseStore.getUsers.map((item, key) => {
													return <option key={key}
																   value={item.id}>{getFullUserNameOrUsername(toJS(baseStore.getUsers), item.id)}</option>
												})}
											</select>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<span className='label'>Заказчик:</span>
										</div>
										<div className="col">
											<select onChange={propChanged}
													name="customer"
													className="form-select"
													value={project && project.customer}
													disabled={disabled}>
												<option value="null">Не назначен</option>
												{baseStore.getCustomers && baseStore.getCustomers.map((item, key) => {
													return <option key={key}
																   value={item.id}>{item.name}</option>
												})}
											</select>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<span className='label'>Дата начала разработки:</span>
										</div>
										<div className="col">
											<DatePicker
												name="start_date"
												className='form-control'
												locale="ru"
												dateFormat="dd.MM.yyyy"
												selected={project.start_date && new Date(project.start_date)}
												onChange={(date) => {
													setProject({
														...project,
														start_date: new Date(date).toISOString().slice(0, 10)
													})
												}}
												disabled={disabled}/>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<span
												className='label'>Дата окончания разработки:</span>
										</div>
										<div className="col">
											<DatePicker
												name="end_date"
												className='form-control'
												locale="ru"
												dateFormat="dd.MM.yyyy"
												selected={project.end_date && new Date(project.end_date)}
												onChange={(date) => {
													setProject({
														...project,
														end_date: new Date(date).toISOString().slice(0, 10)
													})
												}}
												disabled={disabled}/>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<p className='label'>3д модель:</p>
										</div>
										<div className="col d-flex align-items-center">
											<div className="form-switch">
												<input
													onChange={propChanged}
													name="model_3d_done"
													className="form-check-input"
													type="checkbox"
													defaultChecked={project && project.model_3d_done}
													disabled={disabled}/>
											</div>
											<DatePicker
												name="model_3d_done_date"
												className='form-control'
												locale="ru"
												dateFormat="dd.MM.yyyy"
												selected={project.model_3d_done_date && new Date(project.model_3d_done_date)}
												onChange={(date) => dateChanged('model_3d_done_date', date)}
												disabled={disabled}/>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<p className='label'>2д модель:</p>
										</div>
										<div className="col d-flex align-items-center">
											<div className="form-switch">
												<input
													onChange={propChanged}
													name="model_2d_done"
													className="form-check-input"
													type="checkbox"
													defaultChecked={project && project.model_2d_done}
													disabled={disabled}/>
											</div>
											<DatePicker
												name="model_2d_done_date"
												className='form-control'
												locale="ru"
												dateFormat="dd.MM.yyyy"
												selected={project.model_2d_done_date && new Date(project.model_2d_done_date)}
												onChange={(date) => dateChanged('model_2d_done_date', date)}
												disabled={disabled}/>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<p className='label'>Раскрой:</p>
										</div>
										<div className="col d-flex align-items-center">
											<div className="form-switch">
												<input
													onChange={propChanged}
													name="sketch_done"
													className="form-check-input"
													type="checkbox"
													defaultChecked={project && project.sketch_done}
													disabled={disabled}/>
											</div>
											<DatePicker
												className='form-control'
												locale="ru"
												dateFormat="dd.MM.yyyy"
												selected={project.sketch_done_date && new Date(project.sketch_done_date)}
												onChange={(date) => dateChanged('sketch_done_date', date)}
												disabled={disabled}/>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<p className='label'>Отправлено:</p>
										</div>
										<div className="col d-flex align-items-center">
											<div className="form-switch">
												<input onChange={propChanged}
													   name="send_done"
													   className="form-check-input"
													   type="checkbox"
													   defaultChecked={project && project.send_done}
													   disabled={disabled}/>
											</div>
											<DatePicker
												className='form-control'
												locale="ru"
												dateFormat="dd.MM.yyyy"
												selected={project.send_done_date && new Date(project.send_done_date)}
												onChange={(date) => dateChanged('send_done_date', date)}
												disabled={disabled}/>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<p className='label'>Техническая документация:</p>
										</div>
										<div className="col d-flex align-items-center">
											<div className="form-switch">
												<input onChange={propChanged}
													   name="tech_documentation_done"
													   className="form-check-input"
													   type="checkbox"
													   defaultChecked={project && project.tech_documentation_done}
													   disabled={disabled}/>
											</div>
											<DatePicker
												className='form-control'
												locale="ru"
												dateFormat="dd.MM.yyyy"
												selected={project.tech_documentation_done_date && new Date(project.tech_documentation_done_date)}
												onChange={(date) => dateChanged('tech_documentation_done_date', date)}
												disabled={disabled}/>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<p className='label'>Электрическая схема:</p>
										</div>
										<div className="col d-flex align-items-center">
											<div className="form-switch">
												<input onChange={propChanged}
													   name="electric_scheme_done"
													   className="form-check-input"
													   type="checkbox"
													   defaultChecked={project && project.electric_scheme_done}
													   disabled={disabled}/>
											</div>
											<DatePicker
												className='form-control'
												locale="ru"
												dateFormat="dd.MM.yyyy"
												selected={project.electric_scheme_done_date && new Date(project.electric_scheme_done_date)}
												onChange={(date) => dateChanged('electric_scheme_done_date', date)}
												disabled={disabled}/>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<span className='label'>Оплата:</span>
										</div>
										<div className="col">
											<select onChange={propChanged}
													name="payment"
													className="form-control"
													value={paymentOptions.filter((e) => e.value === project.payment)[0]?.value}
													disabled={disabled}>
												{paymentOptions && paymentOptions.map((item, key) => {
													return <option key={key}
																   value={item.value}>{item.name}</option>
												})}
											</select>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<span className='label'>Согласован:</span>
										</div>
										<div className="col">
											<select
												onChange={propChanged}
												name="approval_status"
												className="form-control"
												value={approvalStatusOptions.filter((e) => e.value === project.approval_status)[0]?.value}
												disabled={disabled}>
												{approvalStatusOptions && approvalStatusOptions.map((item, key) => {
													return <option key={key}
																   value={item.value}>{item.name}</option>
												})}
											</select>
										</div>
									</div>

									<div className="row">
										<div className="col">
											<p className='label'>Создал(а):</p>
										</div>
										<div className="col">
											{ createdBy }
										</div>
									</div>

									{alert && <div className="row">
										<div className="col">
											{alert}
										</div>
									</div>}

									{!disabled &&
										<div className='row mt-2'>
											<div className="col d-flex justify-content-end align-items-center">
												{isProcessRunning ?
													<div className="filter_loader_box my-0">
														<div id="loader-4">
															<span></span>
															<span></span>
															<span></span>
														</div>
													</div>
													:
													<div></div>
												}
												{ permissions.project.canChange && 
													<Button onClick={() => updateElement()}
															className='button-outline'
															variant='outline-dark'
															disabled={isProcessRunning}
															>
														Сохранить изменения
													</Button>
												}
											</div>
										</div>
									}
								</form>

								<br/>

								<br/>
								<br/>

								<SubtasksBlock title="Подзадачи" />

								<br/>
								<br/>
							</div>
						</div>
					</div>
				</div>
				: <Loader/>
			}

			<AreYouSureDeleteModal
				show={showAreYouSureModal}
				onHide={handleCloseAreYouSureModal}
				onSureFunc={deleteElement}
				elementId={elementId}
			/>

		</>
	)
}

export default observer(ProjectDetail);