import logo from '../../static/images/Gal’amTec.png';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { observer } from "mobx-react-lite";
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Dropdown } from 'react-bootstrap';
import CustomToggleDropdown from './../parts/CustomToggleDropdown';
import { useStore } from '../../store/hooks';
import { useEffect, useState } from 'react';
import { toJS } from 'mobx';

const Header = observer(() => {
    const authStore = useStore("authStore");
    const [hasAdminRights, setHasAdminRights] = useState(false);
    const { permissions } = authStore;

    useEffect(() => {
        const user = authStore.getUser ? toJS(authStore.getUser) : null;
        if(user) {
            setHasAdminRights(user.is_superuser);
        }
    }, [authStore.getUser]);

    const logOut = () => {
        authStore.logout();
    }

    return (
        <Navbar bg="light" expand="md" className='navbar navbar-expand-lg navbar-dark bg-dark p-3 text-white'>
            <Container fluid>
                <Navbar.Brand>
                    <Link to='/' className='link'>
                        <img src={logo} className='logo' alt="" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav>
                        <Dropdown align="end"
                                  variant="Default">
                            <Dropdown.Toggle id="dropdown-basic" as={CustomToggleDropdown} >
                                <span className='d-inline-block p-2'>
                                    {authStore.getUser?.first_name || (authStore.getUser?.username || 'Пользователь')}
                                </span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} to={`/users/${authStore.getUser?.id}/`}>
                                    Мой профиль
                                </Dropdown.Item>
                                { permissions.overwork.canView && 
                                    <Dropdown.Item as={Link} to={'/overworks/'}>
                                        Переработка
                                    </Dropdown.Item>
                                }
                                { permissions.customer.canView &&
                                    <Dropdown.Item as={Link} to={'/customers/'}>
                                        Заказчики
                                    </Dropdown.Item>
                                }
                                { permissions.users.canChange &&
                                    <Dropdown.Item as={Link} to={'/users/'}>
                                        Сотрудники
                                    </Dropdown.Item>
                                }
                                { hasAdminRights &&
                                    <Dropdown.Item as={Link} to={'https://backend.erp.galamtec.ru/admin/'}>
                                        Админ панель
                                    </Dropdown.Item>
                                }
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={logOut}>
                                    Выйти из системы
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
});

export default Header;