import { observer } from 'mobx-react-lite';
import '../../static/scss/main.scss';
import {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Loader from "../loader/Loader";
import $api from "../../http/api.config";
import {ru} from "date-fns/locale";
import DatePicker from "react-datepicker";
import moment from "moment";
import {convertISOStringToLocalDate} from "../../util/time";
import { useStore } from '../../store/hooks';
import { useRedirectIfNoPermission } from "../../util/permissions/redirect";


const Overworks = observer(() => {
    const authStore = useStore('authStore');
    const [isLoaded, setIsLoaded] = useState(false);
    const [overworks, setOverworks] = useState([]);
    const [nextPageUrl, setNextPageUrl] = useState(null);
    const [showLoadMoreLoader, setShowLoadMoreLoader] = useState(false);
    const [totalWastedTime, setTotalWastedTime] = useState(0);
    const [contentIsLoading, setContentIsLoading] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [organizationId, setOrganizationId] = useState(null);
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString();
    const [startDate, setStartDate] = useState(firstDay);
    const [endDate, setEndDate] = useState(date.toISOString());
    const { permissions } = authStore;
    useRedirectIfNoPermission(permissions.overwork.canView);

    useEffect(() => {
        // получаем список заказчиков
		$api.get('/api/v1/customers/').then((response) => {
            setCustomers([{id: "", name: "Нет"}].concat(response.data));
        });

        loadOverworks();
        loadTotalHours();
    }, []);

    useEffect(() => {
        if(permissions.overwork.canView) {
            loadOverworks();
            loadTotalHours();
        }
    }, [startDate, endDate, organizationId]);

    const loadOverworks = () => {
        setContentIsLoading(true);

        if(permissions.overwork.canView) {
            let url = `/api/v1/overworks/?start_date=${convertISOStringToLocalDate(startDate)}&end_date=${convertISOStringToLocalDate(endDate)}`;
            (organizationId) && (url += `&organization=${organizationId}`);
            $api.get(url).then(response => {
                setOverworks(response.data.results);
                setNextPageUrl(response.data.next);
            }).finally(() => {
                setIsLoaded(true);
                setContentIsLoading(false);
            });
        } else {
            setContentIsLoading(false);
        }
    }

    const loadTotalHours = () => {
        let url = `/api/v1/overworks/hours/?start_date=${convertISOStringToLocalDate(startDate)}&end_date=${convertISOStringToLocalDate(endDate)}`;
        (organizationId) && (url += `&organization=${organizationId}`);
        $api.get(url).then(response => {
			setTotalWastedTime(response.data.wasted_time);
		});
    }

    const loadMore = () => {
        setShowLoadMoreLoader(true);
        if (nextPageUrl) {
            const url = new URL(nextPageUrl);
            $api.get(url.pathname + url.search).then((response) => {
                setOverworks([...overworks, ...response.data.results]);
                setNextPageUrl(response.data.next);
            }).finally(() => {
                setShowLoadMoreLoader(false);
            });
        }
    }

    return (
        <>
			{isLoaded ?
				<div className="detail-page">
					<div className="container-fluid text-start">
                        <div className="bg-white rounded">
                            <div className="row">
                                <div className="col text-center mb-4">
                                    <h2>Мои переработки</h2>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col col-12 col-md-6">
                                    Общее время переработок:
                                </div>
                                <div className="col col-12 col-md-6">
                                    {totalWastedTime ? `${totalWastedTime}ч` : '-'}
                                </div>
                            </div>
                            <div className="row mb-4 g-3">
                                <div className="col col-auto d-flex align-items-center justify-content-start" style={{'fontSize': 12}}>
                                    <div className="datepicker d-flex flex-column align-items-center me-1">
                                        <DatePicker
                                            className="form-control"
                                            selected={new Date(startDate)}
                                            onChange={date => {
                                                setStartDate(new Date(date).toISOString());
                                            }}
                                            locale={ru}
                                            calendarStartDay={1}
                                            popperPlacement="bottom-end"
                                            dateFormat="Pp"
                                            timeCaption="Время"
                                            showTimeSelect
                                        />
                                        <small><span>начальная дата</span></small>
                                    </div>
                                </div>
                                <div className="col col-auto d-flex align-items-center justify-content-start" style={{'fontSize': 12}}>
                                    <div className="datepicker d-flex flex-column align-items-center me-1">
                                        <DatePicker
                                            className="form-control"
                                            selected={new Date(endDate)}
                                            onChange={date => {
                                                setEndDate(new Date(date).toISOString());
                                            }}
                                            locale={ru}
                                            calendarStartDay={1}
                                            popperPlacement="bottom-end"
                                            dateFormat="Pp"
                                            timeCaption="Время"
                                            showTimeSelect
                                        />
                                        <small><span>конечная дата</span></small>
                                    </div>
                                </div>
                                <div className="col col-auto d-flex align-items-center justify-content-start" style={{'fontSize': 12}}>
                                    <div className="d-flex flex-column align-items-center">
                                        <select className="form-control" name='organization' onChange={e => {setOrganizationId(e.target.value)}}>
                                            {customers && customers.map((item, key) => {
                                                return <option key={key} value={item.id || ""}>{item.name}</option>
                                            })}
                                        </select>
                                        <small><span>Организация</span></small>
                                    </div>
                                </div>
                            </div>
                            <div className="content">
                                {(overworks && overworks.length && permissions.overwork.canView) ?
                                    <ul className="list-group">
                                        {
                                            overworks.map(e => {
                                                return <li
                                                    className="list-group-item d-flex justify-content-between align-items-start"
                                                    key={e.id}>
                                                    <div className="ms-2 me-auto">
                                                        <div className="fw-bold mb-2"
                                                             style={{'whiteSpace': 'pre-line'}}>
                                                            {e.name}
                                                        </div>
                                                        <small>{moment(e.start_datetime).format("DD.MM.YYYY HH:mm")}</small> -
                                                        <small>{moment(e.end_datetime).format("DD.MM.YYYY HH:mm")}</small>
                                                    </div>
                                                    <span
                                                        className="badge bg-primary rounded-pill d-block">{e.wasted_time} ч</span>
                                                </li>
                                            })
                                        }
                                    </ul>
                                    :
                                    <div className='row'>
                                        {!contentIsLoading &&
                                            <div className="col text-center">
                                                Пока здесь пусто
                                            </div>
                                        }
                                    </div>
                                }
                            </div>

                            {contentIsLoading &&
                                <div className="row mt-4">
                                    <div className="col text-center">
                                        Идет загрузка...
                                    </div>
                                </div>
                            }

                            {nextPageUrl &&
                                <div className="row mt-4">
                                    <div className='col text-center'>
                                        <Button variant="outline-primary" onClick={loadMore}>
                                            Загрузить еще
                                            {showLoadMoreLoader && <>&nbsp;
                                                <div className="loader-20"></div>
                                            </>}
                                        </Button>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
                : <Loader/>
            }

        </>
    )
});

export default Overworks;