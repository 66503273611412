import { Modal } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import { observer } from "mobx-react-lite";
import useAlert from "../../util/Alert";
import { useEffect, useState } from "react";

const AreYouSureDeleteModal = observer(({
	show, 
	onHide, 
	onSureFunc,
	popupText,
	elementId,
	alert
}) => {
	const [activeButton, setActiveButton] = useState(true);

	const handleCloseButton = () => {
		onHide();
	}

	const handleIfSureButton = async () => {
		if(!elementId) return;
		await onSureFunc(elementId);
		setActiveButton(false);
	}

	return (
		<Modal  show={show}
				onHide={handleCloseButton}>
			<Modal.Header closeButton>
				<Modal.Title>Удалить</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p className="mb-0">
					{popupText || 'Вы уверены что хотите удалить элемент?'}
				</p>

				{ alert && 
					<div className="text-danger mt-3 mb-0">
						{alert}
					</div>
				}
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-between">
				<div className="buttons">
					<Button variant="secondary" onClick={handleCloseButton}>
						Отмена
					</Button>
					<Button onClick={() => handleIfSureButton()} variant="primary" className='ms-2' disabled={!activeButton}>
						Удалить
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	)
});

export default AreYouSureDeleteModal;