import { AxiosResponse } from "axios";
import $api from "../http/api.config";
import { AuthResponse } from "../models/response/AuthResponse";
import { auth } from "../http/api.config";

export default class AuthService {
    static async login(username: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return await auth.post<AuthResponse>('/auth/token/login/', {username, password});
    }

    static async logout(): Promise<AxiosResponse<AuthResponse>> {
        return await $api.post<AuthResponse>('/auth/token/logout/', {});
    }

    static async refresh() {
        return await $api.post<AuthResponse>('/auth/token/refresh/', {});
    }

    static async validate() {
        return await $api.get('/auth/validate-session/');
    }
}