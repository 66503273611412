import { Routes, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Homepage from './Homepage';
import '../../static/scss/main.scss';

import Header from '../general/Header';
import Footer from '../general/Footer';
import ProjectDetail from './Detail/ProjectDetail';
import Customers from './Customers';
import CustomerDetail from './Detail/CustomerDetail';
import {useEffect} from "react";
import {useStore} from "../../store/hooks";
import Users from "./Users";
import UserDetail from "./Detail/UserDetail";
import Overworks from "./Overworks";


const Main = observer(() => {
    const baseStore = useStore('baseStore');
    const authStore = useStore('authStore');

    useEffect(() => {
        baseStore.initialize();
        authStore.getUserData();
    }, []);

    return (
        <>
            <div className='main_wrapper'>
                <Header />

                <Routes>
                    <Route path="/" element={<Homepage />} />

                    <Route path="/projects/" element={<Homepage />} />
                    <Route path="/projects/:id/" element={<ProjectDetail />} />

                    <Route path="/customers/" element={<Customers /> }/>
                    <Route path="/customers/:id/" element={<CustomerDetail /> }/>

                    <Route path="/users/" element={<Users /> }/>
                    <Route path="/users/:id/" element={<UserDetail /> }/>

                    <Route path="/overworks/" element={<Overworks /> }/>
                </Routes>
            </div>

            <Footer />
        </>
    )
});

export default Main;