import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import $api from "../../http/api.config";
import { useStore } from "../../store/hooks";

const SearchBlock = () => {
	const [value, setValue] = useState('');
	const [projects, setProjects] = useState(null);
	const [isFocused, setIsFocused] = useState(false);
	const baseStore = useStore('baseStore');

	// Внутренний таймер для дебаунсинга
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, 500);

		return () => {
			clearTimeout(handler);
		};
	}, [value]);

	// Запрос на сервер
	const findProjects = () => {
		if (debouncedValue) {
			$api.get('/api/v1/projects/', {
				params: {
					'search': debouncedValue,
					'page_size': 10
				}
			}).then(response => {
				if (response.data.results.length && isFocused) {
					setProjects(response.data.results);
				} else {
					setProjects(null);
				}
			});
		} else {
			setProjects(null);
		}
	};

	// Выполняем поиск, когда изменяется `debouncedValue` или `isFocused`
	useEffect(() => {
		if (isFocused && debouncedValue) {
			findProjects();
		} else {
			setProjects(null);
		}

		baseStore.setProjectSearchFieldValue(debouncedValue);
	}, [debouncedValue, isFocused]);

	return (
		<div className="search-wrapper input-group">
			<input
				onChange={e => setValue(e.target.value.trim())}
				onFocus={() => {
					setIsFocused(true);
				}}
				onBlur={(e) => {
					// Проверяем, кликаем ли мы на ссылку внутри списка
					if (!e.currentTarget.contains(e.relatedTarget)) {
						setIsFocused(false);
						setProjects(null); // Очищаем результаты при уходе из поля
					}
				}}
				type="text"
				className="form-control"
				placeholder="Поиск"
			/>

			{projects && isFocused && (
				<ul className="dropdown-menu show">
					{Object.keys(projects).map((key, index) => (
						<li key={index}>
							<Link
								to={'/projects/' + projects[key].id + '/'}
								className="dropdown-item"
								onMouseDown={(e) => {
									e.preventDefault(); // от потери фокуса при клике на ссылку
								}}
							>
								# {projects[key].project_number}, {projects[key].name}
							</Link>
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default observer(SearchBlock);
