import { Navigate, Outlet } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../store/hooks";
import {useEffect, useState} from "react";
import Loader from "../loader/Loader";

const PrivateRoute = (props) => {
    const authStore = useStore('authStore');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkAuthStatus = async () => {
            await authStore.checkAuth();
            setIsLoading(false);
        }

        checkAuthStatus();
    }, [authStore]);

    if(isLoading) {
        return <Loader/>;
    }

    if (authStore.isAuthenticated) {
        return <Outlet/>
    } else {
        return <Navigate to="/login" />;
    }
};
  
export default observer(PrivateRoute);