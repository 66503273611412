const initializeFilterData = () => {
    let data = {};
    if(localStorage.getItem('filter_user')) {
        data['user'] = localStorage.getItem('filter_user');
    } 
    if(localStorage.getItem('filter_status')) {
        data['status'] = localStorage.getItem('filter_status');
    } 
    if(localStorage.getItem('filter_payment')) {
        data['payment'] = localStorage.getItem('filter_payment');
    } 
    if(localStorage.getItem('filter_customer')) {
        data['customer'] = localStorage.getItem('filter_customer');
    } 
    if(localStorage.getItem('filter_user')) {
        data['user'] = localStorage.getItem('filter_user');
    } 
    if(localStorage.getItem('page_size')) {
        data['page_size'] = localStorage.getItem('filter_page_size');
    }
    return data;
}

export { initializeFilterData };