import { observer } from 'mobx-react-lite';
import $api from '../../http/api.config';
import '../../static/scss/main.scss';
import { useEffect, useState } from "react";
import { useStore } from "../../store/hooks";
import { Button } from "react-bootstrap";
import { getFullUserNameOrUsername } from '../../util/user';
import Loader from "../loader/Loader";
import { Link } from 'react-router-dom';
import { useRedirectIfNoPermission } from "../../util/permissions/redirect";


const Users = () => {
    const authStore = useStore('authStore');
    const [isLoaded, setIsLoaded] = useState(false);
    const [showLoadMoreLoader, setShowLoadMoreLoader] = useState(false);
    const [users, setUsers] = useState([]);
    const [nextPageUrl, setNextPageUrl] = useState(null);
    const { permissions } = authStore;
    useRedirectIfNoPermission(authStore.getUser?.is_superuser || (permissions.users.canView && permissions.users.canAdd));

    useEffect(() => {
        // Получаем пользователей
        const params = {
            page_size: 50
        }
        $api.get('/api/v1/users/', {params}).then((response) => {
            setUsers([...response.data.results]);
            setNextPageUrl(response.data.next);
        }).finally(() => {
            setIsLoaded(true);
        });
    }, []);

    const loadMore = () => {
        setShowLoadMoreLoader(true);
        if (nextPageUrl) {
            const url = new URL(nextPageUrl);
            $api.get(url.pathname + url.search).then((response) => {
                setUsers([...users, ...response.data.results]);
                setNextPageUrl(response.data.next);
            }).finally(() => {
                setShowLoadMoreLoader(false);
            });
        }
    }

    return (
        <>
            { isLoaded ?
            <div className="container py-4">
                <div className="group_block text-dark">
                    <div className="title_block d-flex align-items-center mb-3 ps-0">
                        <h3 className="title">
                            Сотрудники
                        </h3>
                        { permissions.users.canAdd &&
                            <Button variant="outline-secondary" className='ms-2'>
                                Добавить
                            </Button> }
                    </div>
                    { users.map((user, index) => {
                        return <div className="row p-3 px-0">
                            <Link 
                                className="col d-flex text-decoration-none link-dark" 
                                data-label="Имя"
                                to={`/users/${user.id}/`}
                            >
                                {++index}. {getFullUserNameOrUsername(users, user.id)}
                            </Link>
                        </div>
                    })}

                    {nextPageUrl &&
                        <div className="row d-flex justify-center">
                            <div>
                                <Button variant="outline-primary" onClick={loadMore}>
                                    Загрузить еще
                                    {showLoadMoreLoader && <>&nbsp;
                                        <div className="loader-20"></div>
                                    </>}
                                </Button>
                            </div>
                        </div>
                    }
                </div>
            </div>
            :
            <Loader/> }
        </>
    )
};

export default observer(Users);