import { makeAutoObservable } from "mobx";
import AuthService from "./../services/AuthService";
import UserService from "./../services/UserService";
import { IUser } from "../models/IUser";
import { IElementPermission } from "../models/IElementPermission";
import PermissionModelTypes from "../util/permissions/types";
import { 
	defaultPermissions,
	getElementPermissions
} from "../util/permissions/permissions";

export default class AuthStore {
	isAuthenticated = false;
	isAuthInProgress = false;
	user_id: number | null;
	username: string;
	is_superuser: boolean | null = false;
	user: IUser | null = null;
	isFetchingUser: boolean = false;
	permissions: IElementPermission = {
		project: defaultPermissions,
		overwork: defaultPermissions,
		subtask: defaultPermissions,
		customer: defaultPermissions,
		users: defaultPermissions
	}

	constructor() {
		makeAutoObservable(this);
		this.username = "";
		this.user_id = null;
	}

	setUserId(user_id: number | null) {
		this.user_id = user_id;
	}

	setUser(user: IUser) {
		this.user = user;
	}

	setUsername(user: IUser) {
		if(user.first_name) {
            this.username = user.first_name;
        } else {
            this.username = user.username;
        }
	}

	setIsAuth(value: boolean) {
		this.isAuthenticated = value;
	}

	setIsAuthInProgress(value: boolean) {
		this.isAuthInProgress = value;
	}

	async login(email: string, password: string) {
		this.setIsAuthInProgress(true);
		try {
			const resp = await AuthService.login(email, password);
			this.setIsAuth(true);
			return resp;
		} catch (err) {
			return err;
		} finally {
			this.setIsAuthInProgress(false);
		}
	}

	async checkAuth() {
		this.setIsAuthInProgress(true);

		try {
			const response = await AuthService.validate();
			if (response.status === 200) {
				this.setIsAuth(true);
			}
		} catch (error) {
			this.setIsAuth(false);
		} finally {
			this.setIsAuthInProgress(false);
		}
	}

	async logout() {
		this.setIsAuthInProgress(true);
		try {
			await AuthService.logout();
			this.setIsAuth(false);
			document.location.href = '/login';
		} finally {
			this.setIsAuthInProgress(false);
		}
	}

	get getUser() {
        if (!this.user && !this.isFetchingUser) {
            this.getUserData();
        }
        return this.user;
    }

	async getUserData() {
		try {
			const response = await UserService.getCurrentUser();
			if (response.data) {
				const user = response.data;
				this.setUser(user);
				this.setUsername(user);

				this.permissions.project = getElementPermissions(PermissionModelTypes.PROJECT, user);
				this.permissions.subtask = getElementPermissions(PermissionModelTypes.SUBTASK, user);
				this.permissions.overwork = getElementPermissions(PermissionModelTypes.OVERWORK, user);
				this.permissions.customer = getElementPermissions(PermissionModelTypes.CUSTOMER, user);
				this.permissions.users = getElementPermissions(PermissionModelTypes.USERS, user);
				this.is_superuser = user?.is_superuser || false;
			}
		} catch (error) {
			console.error('Ошибка при получении данных пользователя:', error);
		}
	}
}