import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Routes, Route} from 'react-router-dom';
import Main from './components/pages/Main';
import Auth from './components/auth/Auth';

import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';

const App = observer(() => {
	useEffect(() => {
		document.title = "Gal'amTec";
	}, []);
	
	return (
		<div className="App">
			<Routes>
				<Route path="/login" element={<Auth />} />
				<Route path="/*" element={<PrivateRoute />}>
					<Route path="*" element={<Main />} />
				</Route>
			</Routes>
		</div>
	);
});

export default App;