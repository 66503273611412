import ProgressBar from 'react-bootstrap/ProgressBar';
import { useEffect } from 'react';
import { useState } from 'react';

function CustomProgressBar({e, maxNumber = 6}) {
    const [element, setElement] = useState(e);
    const [percent, setPercent] = useState(0);
    const [now, setNow] = useState(0);
    const [barColor, setBarColor] = useState('danger');

    useEffect(() => {
        const nowCalculated = nowCalc(element);
        setNow(nowCalculated);
        barColorDefine(nowCalculated);
    }, []);

    const nowCalc = (e) => {
        let result = 0;
        e.model_3d_done && result++;        
        e.model_2d_done && result++;
        e.sketch_done && result++;
        e.send_done && result++;
        e.tech_documentation_done && result++;
        e.electric_scheme_done && result++;

        return Math.round(result * 100 / maxNumber);
    }

    const barColorDefine = (currentValue) => {
        currentValue <= 25 && setBarColor("danger");
        currentValue > 25 && currentValue <= 75 && setBarColor("warning");
        currentValue > 75 && setBarColor("success");
    }

    return (
        <ProgressBar
            className='w-100'
            striped 
            variant={barColor}
            now={now}
            key={1} 
            />
    );
}

export default CustomProgressBar;