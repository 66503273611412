import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import useAlert from "../../util/Alert";
import { observer } from "mobx-react-lite";
import '../../static/scss/modals/overwork-modal.scss';
import $api from "../../http/api.config";
import { useStore } from "../../store/hooks";

const AddCustomerModal = ({show, onHide, setCustomers}) => {
	const authStore = useStore('authStore');
	const [showLoader, setShowLoader] = useState(false);
	const [alert, setAlert] = useAlert();
	const [errors, setErrors] = useState({});
    const [element, setElement] = useState({
        name: ""
    });
	const { permissions } = authStore;

	const addElement = (e) => {
		e.preventDefault();
        
		const errors = validate();
		if(Object.keys(errors).length === 0 && permissions.customer.canAdd) {
			setShowLoader(true);
			$api.post('/api/v1/customers/', element).then(response => {
			    setAlert(response);
                setCustomers(prev => [...prev, response.data]);
				if(response.status >= 200 && response.status < 300) {
					setElement({name: ""});
				}
			}).catch(err => {
			    setAlert(err.response);
			}).finally(() => {
			    setShowLoader(false);
			});
		}
	}

	const validate = () => {
		const errors = {};
		if (!element.name || element.name.trim() === "") {
			errors.name = "Поле не может быть пустым.";
		}
		setErrors(errors);
		return errors;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
        setElement((prev) => ({
            ...prev,
            [name]: value,
        }));
	}

	return (
		<Modal  show={show}
				onHide={onHide}
				scrollable={true}>
			<Modal.Header closeButton>
				<Modal.Title>Новый заказчик</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form method='POST'>
					<div className="row mb-2 text-start">
						<div className="col col-12">
							<label htmlFor="name">Название</label>
						</div>
						<div className="col col-12">
                            <input
                                name="name"
								className="form-control"
								value={element.name}
                                onChange={handleChange}
                            />
							{errors.name && <span
								className="d-block mt-1 text-danger">{errors.name}</span>}
						</div>
					</div>
				</form>
				{alert && <div className="row">
					<div className="col">
						{alert}
					</div>
				</div>}
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-between">
				{showLoader ?
					<div className="filter_loader_box">
						<div id="loader-4">
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
					:
					<div></div>
				}
				<div className="buttons">
					<Button variant="secondary" onClick={e => onHide()}>
						Отмена
					</Button>
					<Button variant="primary" className='ms-2' onClick={addElement} disabled={!permissions.customer.canAdd}>
						Добавить
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	)
}

export default observer(AddCustomerModal);