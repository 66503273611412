import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL;

export const auth = axios.create({
    withCredentials: true,
    baseURL: API_URL,
});

const $api = axios.create({
    withCredentials: true,
    baseURL: API_URL,
});

// Интерсептор для добавления токенов к запросам
$api.interceptors.request.use((config) => {
    const csrfToken = getCookie('csrftoken');

    config.headers = config.headers ?? {}
    if (csrfToken) {
        config.headers['X-CSRFToken'] = csrfToken; // Добавляем CSRF-токен в заголовок
    }

    return config;
});

// Интерсептор для обработки ответов
$api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = {...error.config};
        if(error.response && error.response.status === 401 && error.config && !error.config._isRetry) {
            originalRequest._isRetry = true;
            try {
                // запрос на обновление токена
                await auth.post(`/auth/token/refresh/`);
                return $api.request(originalRequest);
            } catch (err) {
                return Promise.reject(err);
            }
        }
        return Promise.reject(error);
    }
);

function getCookie(name: string) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(`${name}=`)) {
            return cookie.substring(name.length + 1);
        }
    }
    return null; // Вернем null, если cookie не найден
}

export default $api;