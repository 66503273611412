import { useState } from "react";

export default function useAlert() {
    const [alert, setAlert] = useState([]);
    const [timeoutId, setTimeoutId] = useState(0);

    function changeAlert(response) {
        const code = response.status;
        if(!code) setAlert(null);
        if(timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(0);
        }

        let text = "Успешно";
        let alertClassName = 'alert-success';
        if(code >= 200 && code < 300) {
            text = "Успешно";
        } else if(code !== 200) {
            text = 'Ошибка';

            if(code === 403) {
                text = 'Недостаточно прав';
            }
            (response?.data?.detail) && (text = response.data.detail);

            alertClassName = 'alert-danger';
        }

        setTimeoutId(setTimeout(() => {setAlert(null)}, 10000));

        const result = <>
            <div className={'alert mb-1 ' + alertClassName} role="alert">
                {text}
            </div>
        </>;

        setAlert(result);
    };

    return [alert, changeAlert];
};