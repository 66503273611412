import {observer} from "mobx-react-lite";
import {useEffect, useState, useRef} from "react";
import {Bar} from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import {ru} from "date-fns/locale";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
    todayLine,
    statusPlugin,
    weekendPlugin,
    noDataPlugin,
    textOnBarPlugin,
} from "./plugins";
import {colors, periods} from "./variables";
import {getDates, getPeriod} from "./utils";
import "./gantt-chart.scss";

import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    TimeScale,
    elements,
} from "chart.js";
import $api from "../../../http/api.config";
import BaseStore from "../../../store/BaseStore";
import { useStore } from "../../../store/hooks";
import { toJS, reaction } from "mobx";
import { statusOptions } from "../../../util/choices/ProjectOptions";

ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    TimeScale,
);

moment.locale("ru");

const GanttChart = observer(() => {
    const baseStore = useStore('baseStore');
    const [dataset, setDataset] = useState([]);

    const {adjustedStartDate, adjustedEndDate} = getDates();
    const [startDate, setStartDate] = useState(localStorage.getItem('chart_start_date') || adjustedStartDate);
    const [endDate, setEndDate] = useState(localStorage.getItem('chart_end_date') || adjustedEndDate);
    const [selectedPeriod, setSelectedPeriod] = useState(localStorage.getItem('chart_period') || "month");
    const chartRef = useRef(null);
    const [canvasHeight, setCanvasHeight] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        updateChart();
    }, [startDate, endDate, baseStore.projectsFilterSet, baseStore.projectSearchFieldValue]);

    useEffect(() => {
        localStorage.setItem('chart_start_date', startDate);
        localStorage.setItem('chart_end_date', endDate);
    }, [startDate, endDate]);

    useEffect(() => {
        const minHeight = 150;
        const height = 
            dataset.length > 50 ? 
            dataset.length * 40 : 
            ( dataset.length * 50 >= minHeight ? 
                dataset.length * 50 : minHeight
            );
        setCanvasHeight(height);
    }, [dataset]);

    const updateChart = () => {
        setIsLoading(true);
        const searchUrl = `/api/v1/projects/chart-data`;
        let filterParameters = toJS(baseStore.projectsFilterSet);
        filterParameters['start_date'] = new Date(startDate).toISOString().slice(0, 10);
        filterParameters['end_date'] = new Date(endDate).toISOString().slice(0, 10);
        baseStore.projectSearchFieldValue && (filterParameters['project_number_contains'] = baseStore.projectSearchFieldValue);
        $api.get(searchUrl, {
			params: filterParameters
		}).then((response) => {
            setDataset(response.data);
            chartRef.current.update();
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const data = {
        datasets: [
            {
                label: "Branch",
                data: dataset,
                backgroundColor: (ctx) => {
                    if (ctx.dataset.data.length > 0) {
                        const overlapCount = ctx.chart.data.datasets[0].data.filter(
                            (task) =>
                                task.name === ctx.raw.name && // Те же задачи по оси Y
                                task.dates[0] <= ctx.raw.dates[1] && // Начало в пределах диапазона
                                task.dates[1] >= ctx.raw.dates[0], // Конец в пределах диапазона
                        ).length;
                        const baseColor = colors[ctx.raw.status];
                        const transparency = Math.max(0.3, 1 - 0.2 * (overlapCount - 1)); // Чем больше перекрытий, тем прозрачнее
                        return baseColor.replace(", 1)", `, ${transparency})`);
                    }
                },
                borderSkipped: false,
                borderRadius: 10,
                barPercentage: 0.7,
            },
        ],
    };

    const plugins = [todayLine, weekendPlugin, noDataPlugin, textOnBarPlugin];

    const handlePeriodChange = (event) => {
        const value = event.target.value;
        setSelectedPeriod(value);
        getPeriod(event.target.value, setStartDate, setEndDate);
        localStorage.setItem('chart_period', value);
    };

    const options = {
        parsing: {
            xAxisKey: "dates",
            yAxisKey: "project_number",
        },
        maintainAspectRatio: false,
        responsive: true,
        layout: {
            padding: {
                left: 10,
                right: 10,
                bottom: 20,
            },
        },
        indexAxis: "y",
        scales: {
            x: {
                position: "top",
                type: "time",
                time: {
                    unit: "day",
                    displayFormats: {
                        day: "d.MM",
                        hour: "HH:mm",
                    },
                    tooltipFormat: "d.MM.yyyy HH:mm", // Формат для тултипов
                },
                min: moment(startDate).subtract(1, 'days').toDate(),
                max: moment(endDate).add(1, 'days').toDate(),
                align: "center",
                adapters: {
                    date: {
                        locale: ru,
                    },
                },
                stacked: true,
            },
        },
        element: {
            bar: {
                barThickness: 25
            }
        },
        plugins: {
            weekend: {
                weekendColor: "rgba(245, 191, 200, 0.3)",
            },
            legend: {
                display: false,
            },
            tooltip: {
                mode: "nearest",
                displayColors: false,
                callbacks: {
                    label: (context) => {
                        return null;
                    },
                    title: (ctx) => {
                        const startDate = new Date(ctx[0].raw.dates[0]);
                        const endDate = new Date(ctx[0].raw.estimated_end_date);
                        const formattedStartDate = startDate.toLocaleString("ru", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                        });
                        const formattedEndDate = endDate.toLocaleString("ru", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                        });
                        let finished_at = ctx[0].raw.finished_at;
                        if(finished_at) {
                            finished_at = new Date(finished_at).toLocaleString("ru", {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                            }); 
                        }
                        return [
                            `Проект: ${ctx[0].raw.name}`,
                            `Исполнитель: ${ctx[0].raw.employee}`,
                            `Организация: ${ctx[0].raw.organization}`,
                            `Даты: ${formattedStartDate} - ${formattedEndDate}`,
                            `Статус: ${statusOptions.find(e => e.value === ctx[0].raw.db_status)?.name}`,
                            `Дата завершения (последняя смена статуса на "Завершено"): ${finished_at || '-'}`,
                        ];
                    },
                },
            },
            noData: {
                message: isLoading ? "Идет поиск..." : "Нет подходящих проектов"
            }
        },
    };

    return (
        <div className="gantt-chart">
            <div className="chart-configuration">
                {/* <div className="row py-3"> */}
                <div className="row pb-3">
                    <div className="col col-3 d-flex align-items-center">
                        <div>
                            <select
                                id="period-select"
                                value={selectedPeriod}
                                onChange={handlePeriodChange}
                                className="form-select"
                            >
                                {Object.entries(periods).map(([key, label]) => (
                                    <option key={key} value={key}>
                                        {label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col">
                        <div className="raw">
                            <div className="col d-flex align-items-center justify-content-end">
                                <div className="datepicker d-flex flex-column me-1">
                                    <DatePicker
                                        className="form-control"
                                        selected={startDate}
                                        onChange={date => setStartDate(new Date(date.setHours(date.getHours() + 3)))}
                                        dateFormat="dd.MM.yyyy"
                                        locale={ru}
                                        calendarStartDay={1}
                                        popperPlacement="bottom-end"
                                    />
                                    <span>начальная дата</span>
                                </div>
                                <div className="datepicker d-flex flex-column">
                                    <DatePicker
                                        className="form-control"
                                        selected={endDate}
                                        onChange={date => setEndDate(new Date(date.setHours(date.getHours() + 3)))}
                                        dateFormat="dd.MM.yyyy"
                                        locale={ru}
                                        calendarStartDay={1}
                                        popperPlacement="bottom-end"
                                    />
                                    <span>конечная дата</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row chart">
                <div className="canvas_wrapper" style={canvasHeight ? {height: canvasHeight + "px"} : {}}>
                    <Bar ref={chartRef} data={data} options={options} plugins={plugins}/>
                </div>
            </div>
        </div>
    );
});

export default GanttChart;
